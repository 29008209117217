
import { computed, defineComponent, inject, ref } from "vue";
import ZoomableImg from "@/components/ZoomableImg.vue";
import {
  cashOutline,
  chatbubbleOutline,
  checkmarkCircle,
  closeCircle,
  createOutline,
  hourglassOutline
} from "ionicons/icons";
import {
  IonPage,
  IonContent,
  IonModal,
  IonIcon,
  IonRow,
  IonCol,
  IonButton,
  IonSpinner,
  IonTextarea
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonModal,
    IonIcon,
    IonRow,
    IonCol,
    IonButton,
    IonSpinner,
    ZoomableImg,
    IonTextarea
  },
  props: {
    expense: { type: Object },
    edit: Boolean,
    isOpen: Boolean,
    review: Boolean
  },
  emits: ["approveReject", "edit", "closeModal"],
  setup(props, { emit }) {
    const comment = ref(null);
    const isMobile = inject("isMobile") as boolean;
    const handleApproveRejectPaid = (
      approved: boolean,
      paid: { default: false; type: boolean }
    ) => {
      if (!props.expense) {
        return;
      }
      const expense = props.expense;
      expense.paid = paid;
      emit("approveReject", {
        expense,
        comment: comment.value,
        approved
      });
      comment.value = null;
    };

    const displayStatus = computed(() => {
      if (!props.expense) return;
      const status = props.expense.approved === null
        ? "Pending"
        : props.expense.approved === true
        ? "Approved"
        : "Rejected";
      const color = status.toLowerCase();
      const icon = status === "Pending"
          ? hourglassOutline
          : status === "Approved"
          ? checkmarkCircle
          : closeCircle;

      return { status, color, icon };
    });

    return {
      cashOutline,
      chatbubbleOutline,
      checkmarkCircle,
      closeCircle,
      comment,
      createOutline,
      displayStatus,
      isMobile,
      hourglassOutline,
      handleApproveRejectPaid
    };
  }
});
