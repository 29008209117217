
import { useFeature } from "@/composables/useFeature";
import { useNetwork } from "@/composables/useNetwork";
import { useStorage } from "@/composables/useStorage";
import { agent } from "@/agent";
import { defineComponent, ref } from "vue";
import { searchOutline, downloadOutline } from "ionicons/icons";
import { writeFile, utils } from "xlsx";
import { startOfDay, endOfDay } from "date-fns";
import Toolbar from "@/components/Toolbar.vue";
import ExpenseItem from "@/components/ExpenseItem.vue";
import ExpenseModal from "@/components/ExpenseModal.vue";
import EditModal from "@/components/EditModal.vue";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonItem,
  IonDatetime,
  IonSelect,
  IonSelectOption,
  IonCard,
  IonCardContent,
  IonRow,
  IonCol,
  IonLabel,
  IonCheckbox,
  IonRadio,
  IonItemDivider,
  IonItemGroup,
  IonButton,
  IonIcon,
  IonList,
  IonToggle,
  onIonViewWillEnter
} from "@ionic/vue";
export default defineComponent({
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonItem,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonCard,
    IonCardContent,
    IonRow,
    IonCol,
    IonLabel,
    IonCheckbox,
    IonRadio,
    IonItemDivider,
    IonItemGroup,
    IonButton,
    IonIcon,
    IonList,
    IonToggle,
    Toolbar,
    ExpenseItem,
    ExpenseModal,
    EditModal
  },
  setup() {
    const { getAll, getById, getUsers, update, getAllCategories } = agent();
    const { getUser } = useStorage();
    const users = ref([]);
    const categories = ref([]);
    const selectedCategories = ref([]);
    const user = ref();
    const searchClicked = ref(false);
    const approvedDateMode = ref(false);

    onIonViewWillEnter(async () => {
      user.value = await getUser();
      users.value = (await getUsers()).data.sort((a: any, b: any) =>
        a.fullname.localeCompare(b.fullname)
      );
      categories.value = (await getAllCategories()).data;
    });

    const expenseGroups = ref<any>([]);
    const from = ref<any>(null);
    const until = ref<any>(null);
    const approved = ref(true);
    const rejected = ref(false);
    const pending = ref(false);
    const selectedUser = ref<Date | null>(null);
    const selectedExpense = ref(null);
    const expenseUrls: { uuid: string; urls: string[] }[] = []; // Track already loaded urls
    const expenseModal = ref(false);
    const editModal = ref(false);
    const { loading, raiseToast } = useFeature();
    const { isInternet } = useNetwork();

    const clear = () => {
      from.value = null;
      until.value = null;
      approved.value = false;
      rejected.value = false;
      pending.value = false;
      selectedUser.value = null;
    };

    const submit = async () => {
      if (from.value && until.value && from.value > until.value) {
        await raiseToast(
          "From date must be before or equal to until date",
          "danger"
        );
        return;
      }
      if (!approved.value && !rejected.value && !pending.value) {
        await raiseToast("Please select at least one expense status", "danger");
        return;
      }
      const loadingOverlay = await loading("Please wait. Searching expenses");

      const statuses = [];
      if (approved.value === true) {
        statuses.push("approved");
      }
      if (rejected.value === true) {
        statuses.push("rejected");
      }
      if (pending.value === true) {
        statuses.push("pending");
      }

      loadingOverlay.present();
      const params = {
        dateFrom: from.value
          ? startOfDay(new Date(from.value)).toISOString()
          : null,
        dateUntil: until.value
          ? endOfDay(new Date(until.value)).toISOString()
          : null,
        statuses,
        userIds: selectedUser.value ? [selectedUser.value] : null,
        categoryNames: selectedCategories.value,
        dateMode: approvedDateMode.value ? "paid" : null
      };
      const res = await getAll(params);
      if (res && res.data) {
        expenseGroups.value = res.data;
        searchClicked.value = true;
      }
      expenseUrls.length = 0;
      loadingOverlay.dismiss();
    };

    const download = async () => {
      const result: any[][] = [
        [
          "User",
          "Category",
          "Date",
          "Amount",
          "Lodged",
          "Signee",
          "Billed (In Xero)",
          "Approved",
          "Approved Date"
        ]
      ];
      const params = {
        dateFrom: from.value
          ? startOfDay(new Date(from.value)).toISOString()
          : null,
        dateUntil: until.value
          ? endOfDay(new Date(until.value)).toISOString()
          : null,
        approved: approved.value,
        rejected: rejected.value,
        pending: pending.value,
        userIds: selectedUser.value ? [selectedUser.value] : null,
        dateMode: approvedDateMode.value ? "paid" : null,
        categoryNames: selectedCategories.value,
        exclusions: ["imageUrl"],
        sort: "flat"
      };
      const response = await getAll(params);
      if (!response || !response.data) {
        await raiseToast(
          "There was an error fetching those expenses",
          "danger"
        );
        return;
      }
      const expenses = response.data;
      for (const expense of expenses) {
        const approved = expense.approved
          ? "Approved"
          : expense.approved === false
          ? "Rejected"
          : "Pending";
        const paid = expense.paid ? true : false;
        result.push([
          expense.user,
          expense.category,
          expense.date,
          expense.amount,
          expense.createdAt,
          expense.signee,
          paid,
          approved,
          expense.paidDate
        ]);
      }
      const wb = utils.book_new();
      const ws = utils.aoa_to_sheet(result);
      utils.book_append_sheet(wb, ws, "Sheet 1");
      writeFile(wb, "expenses.xlsx");
    };

    const handleOpenExpenseModal = async (expense: any) => {
      const loadingOverlay = await loading("Loading expense");
      loadingOverlay.present();

      try {
        const res = await getById(expense.publicId);
        if (res) {
          selectedExpense.value = res.data;
          expenseModal.value = true;
        }
      } catch (e) {
        if (e instanceof Error) {
          raiseToast(e.message, "error");
        }
      } finally {
        loadingOverlay.dismiss();
      }
    };

    const handleCloseExpenseModal = () => {
      if (expenseModal.value === true) {
        expenseModal.value = false;
      }
    };

    const handleCloseEditModal = async (update: boolean, expense: any) => {
      if (editModal.value === true) {
        editModal.value = false;
      }
      if (update) {
        const loadingOverlay = await loading("Please wait. Updating expense");
        loadingOverlay.present();
        const { update } = agent();
        const res = await update(expense);
        await submit().catch();
        if (res.status === 200) {
          await raiseToast("Updated Expense", "success");
        } else {
          await raiseToast("Error: " + res.message, "danger");
        }
        loadingOverlay.dismiss();
      }
    };

    const handleApproveReject = async (args: any) => {
      if (!isInternet.value) {
        return;
      }
      if (
        !args ||
        !args.expense ||
        (args.approved !== true && args.approved !== false)
      ) {
        return;
      }
      handleCloseExpenseModal();
      const loadingOverlay = await loading("Please wait. Updating expense");
      loadingOverlay.present();
      args.expense.approved = args.approved;
      if (args.comment && args.comment.length) {
        args.expense.comment = `${user.value.firstName} ${user.value.lastName}: ${args.comment}`;
      }

      const res = await update(args.expense);
      if (res && res.status !== 200) {
        await raiseToast(res.message, "danger");
      } else {
        raiseToast(args.approved ? "Approved!" : "Rejected!", "success");
      }
      await submit();
      loadingOverlay.dismiss();
    };

    return {
      from,
      until,
      approved,
      rejected,
      pending,
      selectedUser,
      user,
      users,
      expenseGroups,
      clear,
      submit,
      searchOutline,
      downloadOutline,
      expenseModal,
      editModal,
      selectedExpense,
      handleApproveReject,
      handleOpenExpenseModal,
      handleCloseExpenseModal,
      handleCloseEditModal,
      searchClicked,
      approvedDateMode,
      download,
      categories,
      selectedCategories
    };
  }
});
