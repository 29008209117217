
import { defineComponent, ref } from "vue";
import { arrowBackOutline } from "ionicons/icons";
import { organizations } from "@/constants";
import ExpenseInput from "@/components/ExpenseInput.vue";

import {
  IonPage,
  IonHeader,
  IonButton,
  IonToolbar,
  IonContent,
  IonModal,
  IonIcon
} from "@ionic/vue";

export default defineComponent({
  components: {
    ExpenseInput,
    IonPage,
    IonHeader,
    IonButton,
    IonToolbar,
    IonContent,
    IonModal,
    IonIcon
  },
  props: {
    categories: { type: Array, required: true },
    expense: { type: Object, required: true },
    isOpen: Boolean,
    user: { type: Object, required: true }
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const expenseInput = ref();
    const submit = async (expense: any) => {
      emit("closeModal", true, expense);
    };
    return {
      arrowBackOutline,
      expenseInput,
      organizations,
      submit
    };
  }
});
