
import { IonItem, IonIcon, IonRow, IonCol } from "@ionic/vue";
import {
  cashOutline,
  chatbubbleOutline,
  checkmarkCircle,
  closeCircle,
  close,
  hourglassOutline
} from "ionicons/icons";

export default {
  name: "Expenses",
  components: {
    IonItem,
    IonIcon,
    IonRow,
    IonCol
  },
  props: {
    expense: Object,
    showStatus: { type: Boolean, default: false },
    showUser: { type: Boolean, default: false }
  },
  setup() {
    return {
      cashOutline,
      chatbubbleOutline,
      checkmarkCircle,
      close,
      closeCircle,
      hourglassOutline
    };
  }
};
