import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20393bb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "imageContainer" }
const _hoisted_2 = { class: "textContainer" }
const _hoisted_3 = { class: "margin-top padding bordered" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZoomableImg = _resolveComponent("ZoomableImg")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, {
    "is-open": _ctx.isOpen,
    onWillDismiss: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('closeModal')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_page, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ZoomableImg, {
                  onCloseModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal'))),
                  sources: 
              _ctx.expense.imageUrls && _ctx.expense.imageUrls.length
                ? _ctx.expense.imageUrls
                : _ctx.expense.imageUrl
                ? [_ctx.expense.imageUrl]
                : ['https://expenses.thinkspatial.com.au/placeholder.jpg']
            
                }, null, 8, ["sources"])
              ]),
              _createVNode(_component_ion_row, {
                class: "ion-justify-content-end ion-align-items-center statusBar",
                style: _normalizeStyle(`background-color: var(--ion-color-${_ctx.displayStatus.color})`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    size: "large",
                    icon: _ctx.displayStatus.icon
                  }, null, 8, ["icon"]),
                  _createElementVNode("span", null, _toDisplayString(_ctx.displayStatus.status), 1)
                ]),
                _: 1
              }, 8, ["style"]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, { size: "8" }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.expense.user), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_col, {
                      size: "4",
                      style: {"text-align":"right"}
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, "$" + _toDisplayString(_ctx.expense.amount), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_row, { class: "borderBottom ion-justify-content-between ion-align-items-end" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        _createElementVNode("h5", null, _toDisplayString(_ctx.expense.organization), 1),
                        _createElementVNode("h6", null, _toDisplayString(_ctx.expense.category), 1)
                      ]),
                      _: 1
                    }),
                    (_ctx.edit)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit', _ctx.expense))),
                          fill: "clear",
                          shape: "round"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              color: "primary",
                              slot: "icon-only",
                              icon: _ctx.createOutline
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                (_ctx.expense.description)
                  ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(_ctx.expense.description), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.expense.comment)
                  ? (_openBlock(), _createBlock(_component_ion_row, {
                      key: 1,
                      class: "ion-align-items-center ion-padding-bottom"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, { size: "2" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: _ctx.chatbubbleOutline,
                              size: "large"
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_3, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.expense.comment), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.review && !_ctx.expense.comment)
                  ? (_openBlock(), _createBlock(_component_ion_row, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_textarea, {
                              class: "bordered",
                              modelValue: _ctx.comment,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.comment) = $event)),
                              inputmode: "text",
                              maxlength: "200",
                              name: "comment",
                              placeholder: "Optional comment for user"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.review)
                  ? (_openBlock(), _createBlock(_component_ion_row, {
                      key: 3,
                      class: "ion-justify-content-between ion-padding-bottom ion-padding-right ion-margin-top"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              class: "btn",
                              shape: "round",
                              expand: "full",
                              color: "approved",
                              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleApproveRejectPaid(true, true)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: _ctx.cashOutline,
                                  style: {"color":"#fff"}
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              class: "btn",
                              shape: "round",
                              expand: "full",
                              color: "rejected",
                              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleApproveRejectPaid(false)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  large: "",
                                  icon: _ctx.closeCircle,
                                  style: {"color":"#fff"}
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              class: "btn",
                              shape: "round",
                              expand: "full",
                              color: "approved",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleApproveRejectPaid(true)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "icon-only",
                                  icon: _ctx.checkmarkCircle,
                                  style: {"color":"#fff"}
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["is-open"]))
}