import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fda2d94"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "ion-margin-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return ($props.expense)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        class: "ion-item-no-right-padding ion-no-padding-top",
        button: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openExpenseModal', $props.expense)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { class: "ion-no-padding" }, {
            default: _withCtx(() => [
              ($props.showUser)
                ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($props.expense.user), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", null, _toDisplayString($props.expense.category), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_row, { slot: "end" }, {
            default: _withCtx(() => [
              _createElementVNode("p", _hoisted_2, "$" + _toDisplayString($props.expense.amount), 1),
              ($props.expense.comment)
                ? (_openBlock(), _createBlock(_component_ion_icon, {
                    key: 0,
                    style: {"margin-right":"10px","margin-top":"7px"},
                    icon: $setup.chatbubbleOutline,
                    size: "large"
                  }, null, 8, ["icon"]))
                : _createCommentVNode("", true),
              ($props.showStatus)
                ? (_openBlock(), _createBlock(_component_ion_col, {
                    key: 1,
                    color: "danger",
                    style: _normalizeStyle(
          `background-color: var(--ion-color-${
            $props.expense.approved === true
              ? 'approved'
              : $props.expense.approved === false
              ? 'rejected'
              : 'pending'
          }); padding-top: 9px`
        )
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        size: "large",
                        icon: $props.expense.approved === true
              ? $setup.checkmarkCircle
              : $props.expense.approved === false
              ? $setup.closeCircle
              : $setup.hourglassOutline
          ,
                        style: {"color":"#fff"}
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["style"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}